import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['colorSelect', 'list'];

  initialize() {
    window.console.log('Engagements Controller Initialized!');
  }

  connect() {
    const top = this.data.get('top');
    $('main').scrollTop(top);
  }

  toggleEngagement(event) { // mouseup
    const $target = $(event.target);

    if ($target[0] !== this.clickedEngagement) {
      // clickedEngagement is set in #buildEngagements, which is triggered by the
      // mousedown event. This ensures the mouseup isn't from a click occuring elsewhere.
      this.clickedEngagement = null;
      return;
    }

    const data = $target.data();

    if (data.engagementId) {
      this.destroyEngagement(data, (html) => {
        $target.replaceWith(html);
      });
    } else {
      this.createEngagement(data, (html) => {
        $target.replaceWith(html);
      });
    }
  }

  buildEngagements(event) { // mousedown
    if (event.button !== 0) { return; }

    this.clickedEngagement = event.target;
    const $target = $(this.clickedEngagement);

    let show = true;

    $target.one('mouseout', () => { show = false; });
    $(window).one('mouseup', () => { show = false; });

    window.setTimeout(() => {
      if (show) { this.openEngagementBuilder($target.data()); }
    }, 300);
  }

  openEngagementBuilder(data) {
    $.ajax({
      method: 'GET',
      url: '/engagements/build_many',
      data: {
        engagement: {
          date: data.date,
          color: this.color,
          engageable_id: data.engageableId,
          engageable_type: data.engageableType,
          has_engagements_id: data.hasEngagementsId,
          has_engagements_type: data.hasEngagementsType,
        },
      },
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      dataType: 'script',
    });
  }

  endEngagementBlock(event) {
    if (!window.confirm('Are you sure?')) { return; }

    const $row = $(event.target).closest('.engageable');
    const $engagements = $row.find('.engagement');
    const data = $engagements.first().data();

    $.ajax({
      method: 'PUT',
      url: '/engagement_blocks/placeholder',
      data: {
        engagement_block: {
          start_date: data.date,
          end_date: $engagements.last().data('date'),
          has_engageable_blocks_id: data.engageableId,
          has_engageable_blocks_type: data.engageableType,
          has_engagement_blocks_id: data.hasEngagementsId,
          has_engagement_blocks_type: data.hasEngagementsType,
        },
      },
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      dataType: 'script',
      complete: () => {
        $row.hide(
          250,
          () => { $row.detach(); },
        );
      },
    });
  }

  onCellClick() {
    this.sinceLastCellClick = this.getTimestamp();
  }

  createEngagement(data, callback) {
    $.ajax({
      method: 'POST',
      url: '/engagements',
      data: {
        engagement: {
          color: this.color,
          engageable_id: data.engageableId,
          engageable_type: data.engageableType,
          has_engagements_id: data.hasEngagementsId,
          has_engagements_type: data.hasEngagementsType,
          date: data.date,
        },
      },
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      dataType: 'html',
      complete: (response) => {
        callback(response.responseText);
      },
    });
  }

  destroyEngagement(data, callback) {
    $.ajax({
      method: 'DELETE',
      url: `/engagements/${data.engagementId}`,
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      dataType: 'html',
      complete: (response) => {
        callback(response.responseText);
      },
    });
  }

  updateColor(event) {
    const $target = $(event.target);
    const color = $target.data('color');
    this.color = color;

    console.log(color);

    $(this.colorSelectTargets).each(function () {
      const elColor = $(this).data('color');
      $(this).removeClass(`bg-${elColor}-700`).addClass(`text-${elColor}-600 bg-white`);
    });

    $target.removeClass(`text-${color}-600 bg-white`).addClass(`bg-${color}-700 text-gray-100`);

    $.ajax({
      method: 'PATCH',
      url: 'engagements/set_color',
      data: {
        color,
      },
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      dataType: 'script',
    });
  }

  getTimestamp() {
    const date = new Date();
    return date.getTime();
  }

  get color() {
    return this.data.get('color');
  }

  set color(val) {
    this.data.set('color', val);
  }
}
