import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'hasEngagementBlocksId',
    'hasEngagementBlocksType',
    'hasEngageableBlocks',
    'hasEngageableBlocksId',
    'hasEngageableBlocksType',
    'useStandardHasEngagableBlocks',
    'useCustomHasEngagableBlocks',
    'customHasEngagableBlocksName',
  ]

  initialize() {
    window.console.log('EngagementBlockForm Controller Initializes!');
  }

  connect() {
    this.updateEngageableOptions().then(() => {
      this.showHasEngageableOptions();
    });

    this.updateHasEngageableBlocks();
    $('.custom_select').hide();
  }

  showHasEngageableOptions() {
    if ($(this.hasEngageableBlocksTarget).find('option').length === 0) {
      // If there are zero options for the Engageable dropdown, show Custom option only.
      this.useCustomHasEngagableBlocks();
      $('.job_select').detach();
    } else {
      this.useStandardHasEngagableBlocks();
    }
  }

  updateEngageableOptions() {
    // Reads the Engagables table to see which Engageable options are available
    // for this HasEngagement. This (hack) is leveraging the rather complex
    // logic of the @engageable assigns in EngagmentsController.
    return new Promise((resolve) => {
      $('.engageable').each((_i, engageable) => {
        const engagementData = $(engageable).find('td[data-date]').first().data();

        if (engagementData.hasEngagementsType === this.hasEngagementBlocksType
            && engagementData.hasEngagementsId.toString() === this.hasEngagementBlocksId) {
          $(this.hasEngageableBlocksTarget).find('option').each((_j, option) => {
            const $option = $(option);

            if (engagementData.engageableId.toString() === $option.val()
                && engagementData.engageableType === $option.data('type')) {
              $option.detach();
            }
          });
        }
      });
      resolve();
    });
  }

  updateHasEngageableBlocks() {
    // When the user selects an Engageable, update the hidden fields that hold
    // :has_engabeable_blocks_id and :has_engageable_blocks_type.
    const id = this.$hasEngageableBlocks.val();
    const type = this.$hasEngageableBlocks.find('option:selected').data('type');
    $(this.hasEngageableBlocksIdTarget).val(id);
    $(this.hasEngageableBlocksTypeTarget).val(type);
  }

  useStandardHasEngagableBlocks(event) {
    let speed = 100;

    if (typeof event === 'undefined') { // If this is called from connect()
      speed = 0;
    } else {
      event.preventDefault();
    }

    this.makeTabInactive($(this.useCustomHasEngagableBlocksTarget));
    this.makeTabActive($(this.useStandardHasEngagableBlocksTarget));
    $(this.hasEngageableBlocksTarget).closest('.job_select').show(speed).focus();
    $(this.hasEngageableBlocksTarget).focus();
    $(this.customHasEngagableBlocksNameTarget).val('').closest('.custom_select').hide(speed);
    $(this.customHasEngagableBlocksNameTarget).prop('required', false);
  }

  useCustomHasEngagableBlocks(event) {
    let speed = 100;

    if (typeof event === 'undefined') {
      speed = 0;
    } else {
      event.preventDefault();
    }

    this.makeTabActive($(this.useCustomHasEngagableBlocksTarget));
    this.makeTabInactive($(this.useStandardHasEngagableBlocksTarget));
    $(this.hasEngageableBlocksTarget).closest('.job_select').hide(speed);
    $(this.customHasEngagableBlocksNameTarget).closest('.custom_select').show(speed);
    $(this.customHasEngagableBlocksNameTarget).prop('required', true).focus();
  }

  makeTabActive(el) {
    el.addClass('bg-cool-gray-400 text-white').removeClass('bg-white text-gray-500');
  }

  makeTabInactive(el) {
    el.addClass('bg-white text-gray-500').removeClass('bg-cool-gray-400 text-white');
  }

  keyboardAdd(event) {
    if (event.key !== 'Enter') { return; }
    event.preventDefault();
    this.$form.find('[type=submit][value=Add]').click(); // Triggers the presence validation.
  }

  get $hasEngageableBlocks() {
    return $(this.hasEngageableBlocksTarget);
  }

  get hasEngagementBlocksId() {
    return this.hasEngagementBlocksIdTarget.value;
  }

  get hasEngagementBlocksType() {
    return this.hasEngagementBlocksTypeTarget.value;
  }

  get $form() {
    return $(this.element).find('form');
  }
}
