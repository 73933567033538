import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['startDate', 'startTime', 'endDate', 'endTime'];

  connect() {
    console.log('CX Dates controller connected!');

    this.cxId = this.data.get('cxId');
  }

  checkAvailability() {
    console.log('checkEnd ran!');
    const startDate = $(this.startDateTarget).val();
    const startTime = $(this.startTimeTarget).val();
    const endDate = $(this.endDateTarget).val();
    const endTime = $(this.endTimeTarget).val();

    const spaceNeeded = $('[name="cx_request[space_needed]"]').val();

    const huskyShopRoom = $('[name="cx_request[husky_shop_room]"]').eq(1).prop("checked");
    const huskyDemoArea = $('[name="cx_request[husky_demo_area]"]').eq(1).prop("checked");
    const huskyConfRoom = $('[name="cx_request[husky_conf_room]"]').eq(1).prop("checked");
    
    const everbiltSpace_1 = $('[name="cx_request[everbilt_space_1]"]').eq(1).prop("checked");
    const everbiltSpace_2 = $('[name="cx_request[everbilt_space_2]"]').eq(1).prop("checked");
    const everbiltSpace_3 = $('[name="cx_request[everbilt_space_3]"]').eq(1).prop("checked");
    const everbiltSpace_4 = $('[name="cx_request[everbilt_space_4]"]').eq(1).prop("checked");
    const everbiltConfRoom = $('[name="cx_request[everbilt_conf]"]').eq(1).prop("checked");

    if (startDate.length && startTime.length && endDate.length && endTime.length){
      const dateTimes = {
        startDate: startDate, 
        startTime: startTime, 
        endDate: endDate, 
        endTime: endTime
      };
      const cx_attrs = {
        spaceNeeded: spaceNeeded,
        huskyShopRoom: huskyShopRoom,
        huskyDemoArea: huskyDemoArea,
        huskyConfRoom: huskyConfRoom,
        everbiltSpace_1: everbiltSpace_1,
        everbiltSpace_2: everbiltSpace_2,
        everbiltSpace_3: everbiltSpace_3,
        everbiltSpace_4: everbiltSpace_4,
        everbiltConfRoom: everbiltConfRoom
      };
      this.getAvailability(dateTimes, cx_attrs);
    } else {
      console.log('not present');
    }
  }

  getAvailability(dateTimes, cx_attrs) {
    $.ajax({
      method: 'GET',
      url: `/cx_calendars/check_availability`,
      data: {
        dateTimes: dateTimes,
        cx_attrs: cx_attrs,
        cx_id: this.cxId
      },
      dataType: 'json',
      success(data) {
        console.log(data);
        const dateTimeFields = $('.dateTime');
        const dateTimeWarning = $('.dateTimeWarning');
        if (data == true) {
          dateTimeFields.addClass('bg-red-100').removeClass('bg-green-100');
          dateTimeWarning.removeClass('hidden');
        } else {
          dateTimeFields.removeClass('bg-red-100').addClass('bg-green-100');
          dateTimeWarning.addClass('hidden');
        }
      },
    });
  }

}