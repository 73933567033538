import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React from 'react';
import {
  addDays, addWeeks, addMonths, parseISO, differenceInCalendarDays, differenceInCalendarWeeks,
  differenceInCalendarMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek,
  startOfDay, isEqual,
} from 'date-fns';
import DateRangePicker from 'react-date-range/dist/components/DateRangePicker';
import PropTypes from 'prop-types';

/*
  props = {
    onChange={
  }
*/

export default class DateRangeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: this._coerceDate(this._defaultStartDate(props)),
      endDate: this._coerceDate(this._defaultEndDate(props)),
      relativeEndDate: props.relativeEndDate,
      relativeStartDate: props.relativeStartDate,
      isFocused: false,
    };
    this.changeCallback = props.onChange || (() => {});
    this.onChange = this._onChange.bind(this);
    this.onInputFocus = this._onInputFocus.bind(this);
    this.keypressed = this._keypressed.bind(this);
  }

  _onInputFocus(e) {
    this.setState({ isFocused: true });
  }

  _keypressed(e) {
    if (e.keyCode === 8 || e.keyCode === 46) {
      this.onChange({ selection: { startDate: '', endDate: '' } });
    }
  }

  _coerceDate(date) {
    if (date) return date.toISOString().split('T')[0];

    return '';
  }

  get ranges() {
    if (this.state.startDate && this.state.endDate) {
      return [
        {
          startDate: parseISO(this.state.startDate),
          endDate: parseISO(this.state.endDate),
          key: 'selection',
        },
      ];
    }

    return [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ];
  }

  _renderPicker() {
    if (!this.state.isFocused) return '';
    return (
      <DateRangePicker
        onChange={this.onChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={this.ranges}
        direction="horizontal"
      />
    );
  }

  render() {
    return (
      <div className="grid grid-cols-2 gap-4">
        <div className="rounded-md shadow-sm">
          <input
            type="text"
            onFocus={this.onInputFocus}
            onKeyDown={this.keypressed}
            className="form-input block w-full"
            name="start_date"
            placeholder="Start Date"
            value={this.state.startDate}
            readOnly={true}
          ></input>
          <input
            type="hidden"
            name="relative_start_date"
            value={JSON.stringify(this.state.relativeStartDate)}
          ></input>
        </div>
        <div className="rounded-md shadow-sm">
          <input
            type="text"
            onKeyDown={this.keypressed}
            onFocus={this.onInputFocus}
            className="form-input block w-full"
            name="end_date"
            placeholder="End Date"
            value={this.state.endDate}
            readOnly={true}
          ></input>
          <input
            type="hidden"
            name="relative_end_date"
            value={JSON.stringify(this.state.relativeEndDate)}
          ></input>
        </div>
        {this._renderPicker()}
      </div>
    );
  }

  // todo: add input manipulation here
  _onChange(range) {
    const { startDate, endDate } = range.selection;
    // keep focus as long as change is to start date and not end date
    const startChanged = !(this._coerceDate(startDate) === this.state.startDate);
    const endChanged = !(this._coerceDate(endDate) === this.state.endDate);
    const isFocused = !(!isEqual(startDate, endDate) || (startChanged !== endChanged));
    const relativeStartDate = this._calcRelative(startDate);
    const relativeEndDate = this._calcRelative(endDate);

    const today = new Date();
    this.setState({
      startDate: this._coerceDate(startDate),
      endDate: this._coerceDate(endDate),
      relativeEndDate,
      relativeStartDate,
      isFocused,
    });
    this.changeCallback(relativeStartDate, relativeEndDate);
  }

  // calculate relative to current date ie: 10 days ago 1 month ago 1 week ago
  _calcRelative(rawDate) {
    if (!rawDate) return [];

    const today = startOfDay(new Date());
    const date = startOfDay(rawDate);
    let startDate = today;
    let endDate = date;
    let multiplier = 1;
    if (today > date) {
      startDate = date;
      endDate = today;
      multiplier = -1;
    }
    const monthDiff = differenceInCalendarMonths(endDate, startDate, 'ceil') * multiplier;
    const weekDiff = differenceInCalendarWeeks(endDate, startDate, 'ceil') * multiplier;
    const dayDiff = differenceInCalendarDays(endDate, startDate, 'ceil') * multiplier;

    if (isEqual(startOfMonth(date), date)) return ['month_start', monthDiff];
    if (isEqual(startOfDay(endOfMonth(date)), date)) return ['month_end', monthDiff];
    if (monthDiff !== 0) return ['months', monthDiff];
    if (isEqual(startOfWeek(date), date)) return ['week_start', weekDiff];
    if (isEqual(startOfDay(endOfWeek(date)), date)) return ['week_end', weekDiff];
    if (weekDiff !== 0) return ['weeks', weekDiff];

    return ['days', dayDiff];
  }

  _fromRelative([key, mult]) {
    const today = new Date();
    if (key === 'month_start') return startOfMonth(addMonths(today, mult));
    if (key === 'month_end') return startOfDay(endOfMonth(addMonths(today, mult)));
    if (key === 'months') return startOfDay(addMonths(today, mult));
    if (key === 'week_start') return startOfWeek(addWeeks(today, mult));
    if (key === 'week_end') return startOfDay(endOfWeek(addWeeks(today, mult)));
    if (key === 'week') return startOfDay(addWeeks(today, mult));

    return startOfDay(addDays(today, mult));
  }

  _defaultStartDate(props) {
    const rel = props.relativeStartDate;
    if (rel && rel.length > 0) return this._fromRelative(rel);

    return '';
  }

  _defaultEndDate(props) {
    const rel = props.relativeEndDate;
    if (rel && rel.length > 0) return this._fromRelative(rel);

    return '';
  }
}

// props documentation / validation
DateRangeFilter.propTypes = {
  onChange: PropTypes.func,
  relativeStartDate: PropTypes.array,
  relativeEndDate: PropTypes.array,
};
