import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    console.log('Froala controller initialized!');
  }

  connect() {
    this.getFroalaParams();
  }

  getFroalaParams() {
    const t = this;

    $.ajax({
      method: 'GET',
      url: '/froala_initializations',
      dataType: 'json',
      success(response) {
        t.initializeFroala(response.froala, response.tag_helper_names);
      },
    });
  }

  initializeFroala(froala, tag_helper_names) {
    const t = this;
    const include_at_functionality = window.location.href.split('/').includes('discussions');

    var editor = new FroalaEditor('.froala', {
      key: 'gVG3C-8D1J2C6A3B4E2ud1BI1IMNBUMRWAi1AYMSTRBUZYB-16D4E3D2B2C3I2E2B10A2E2==',
      enter: FroalaEditor.ENTER_BR,
      attribution: false,
      heightMin: 400,
      placeholderText: '',
      toolbarSticky: true,
      scrollableContainer: '.froala-container',
      imageResizeWithPercent: true,
      toolbarButtons: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'textColor',
        'align',
        'clearFormatting',
        'paragraphFormat',
        'formatUL',
        'formatOL',
        'insertTable',
        'insertHR',
        'insertLink',
        'insertImage',
        'insertFile',
        'undo',
        'redo',
        'html'
        ],
        paragraphFormat: {
          N: 'Normal',
          H1: 'Heading 1',
          H2: 'Heading 2',
          H3: 'Heading 3',
          H4: 'Heading 4'
        },
      imageUploadToS3: {
        bucket: froala.bucket,
        region: 's3',
        keyStart: froala.keyStart,
        params: {
          acl: froala.params.acl, // ACL according to Amazon Documentation.
          AWSAccessKeyId: froala.params.AWSAccessKeyId, // Access Key from Amazon.
          policy: froala.params.policy, // Policy string computed in the backend.
          signature: froala.params.signature, // Signature computed in the backend.
        }
      },
      fileUploadToS3: {
        bucket: froala.bucket,
        region: 's3',
        keyStart: froala.keyStart,
        params: {
          acl: froala.params.acl, // ACL according to Amazon Documentation.
          AWSAccessKeyId: froala.params.AWSAccessKeyId, // Access Key from Amazon.
          policy: froala.params.policy, // Policy string computed in the backend.
          signature: froala.params.signature, // Signature computed in the backend.
        }
      },

      events: {
        initialized: function() {
          if (include_at_functionality) {
            t.atMention(editor, tag_helper_names);
          }
        }
      }
      
    });

  }

  atMention(editor, tag_helper_names) {
    var datasource = tag_helper_names;

    // Build data to be used in At.JS config.
    var names = $.map(datasource, function (value, i) {
      return {
        'id': i, 'name': value
      };
    });

    // Define config for At.JS.
    var config = {
      at: "@",
      data: names,
      displayTpl: '<li>${name}</li>',
      limit: 200
    }

    $(editor.el).atwho(config);

    editor.events.on('keydown', function (e) {
      if (e.which == FroalaEditor.KEYCODE.ENTER && $(editor.el).atwho('isSelecting')) {
        return false;
      }
    }, true);
  }

}


