/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../stylesheets/application.scss';
import '@fortawesome/fontawesome-free/js/all';

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import toastr from 'toastr/toastr';
import index from 'cd-atwho';

toastr.options = {
  positionClass: 'toast-bottom-right',
  preventDuplicates: true,
  preventOpenDuplicates: true,
};

const application = Application.start();
const context = require.context('controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
window.toastr = toastr;

/* eslint-disable */
let containerScrollTops = {};

['click'].forEach((evt) => {
  document.addEventListener(evt, () => {
    document
      .querySelectorAll('[data-turbolinks-preserve-scroll-container]')
      .forEach((ele) => {
        containerScrollTops[ele.dataset.turbolinksPreserveScrollContainer] =
          ele.scrollTop;
      });
  });
});

// cleanup select2
document.addEventListener('turbolinks:before-cache', () => {
  $('.is-select2').each((n, el) => {
    const $el = $(el);
    if ($el.select2) $el.select2('destroy');
  });
});

['turbolinks:load'].forEach((evt) => {
  document.addEventListener(evt, () => {
    document
      .querySelectorAll('[data-turbolinks-preserve-scroll-container]')
      .forEach((ele) => {
        const containerScrollTop =
          containerScrollTops[ele.dataset.turbolinksPreserveScrollContainer];
        if (containerScrollTop) ele.scrollTo(0, containerScrollTop);
      });

    containerScrollTops = {};
  });
});
/* eslint-enable */
