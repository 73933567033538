import { Controller } from 'stimulus';
import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import DateRangeFilter from '../../components/DateRangeFilter';

export default class extends Controller {
  // emit change event to wrapper div
  onChange(startDate, endDate) {
    this.element.dispatchEvent(new Event('change'));
  }

  disconnect() {
    this.root.unmount();
  }

  _parseCond(cond) {
    if (!cond || cond === '') return [];

    return JSON.parse(cond);
  }

  connect() {
    if (this.root) this.root.unmount();
    this.root ||= createRoot(this.element);
    this.root.render(
      <DateRangeFilter
        onChange={this.onChange.bind(this)}
        relativeStartDate={this._parseCond(this.element.dataset.start)}
        relativeEndDate={this._parseCond(this.element.dataset.end)}
      />,
    );
  }
}
